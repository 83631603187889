export const colors = {
  primary: 'blue',
  secondary: 'black',
}

export const fonts = {
  font: 'Lato',
  bold: '700',
  semibold: '600',
  regular: '400',
  light: '300'
}

export const align = {
  center: 'center',
  left: 'left',
  right: 'right'
}

export const lang = {
  title_vm: 'Le titre 2'
}