import styled, { css } from "styled-components";

// BASE APP VARIABILISE


export const MainWrapper = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-size: cover;
  background-position: ${props => props.theme.backgroundDesktopPosition || 'bottom right'};
  background-image: url('${props => props.theme.backgroundDesktop}');
  background-repeat: no-repeat;
  @media (max-width: 991px){
      background-image: url('${props => props.theme.backgroundMobile}');
      background-position: ${props => props.theme.backgroundPositionMobile || 'bottom right'};
      background-size: cover;
      background-repeat: no-repeat;
    }
`;

export const Logo = styled.img`
  width: ${props => props.theme.logoWidth || '220px'};
  height: auto;
  padding-left: 40px;
  max-width: 220px;
  margin-right: 20px;
  @media (max-width: 991px){
    width: ${props => props.theme.logoWidthMobile || '100%'};
    max-width: ${props => props.theme.logoWidthMobile || '220px'};
    position: relative;
    z-index: 2;
    left: inherit;
    padding-left: 0px;
    margin-right: 0px;
    text-align: center;
    margin: 0 auto;
  }
`;

export const ImageHeader = styled.img`
 display: none;
  @media (max-width: 767px){
    width: 80%;
    position: relative;
    z-index: 2;
    height: auto;
    left: inherit;
    padding-left: 0px;
    margin-right: 0px;
    text-align: center;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    display: block;
  }
`;



export const Wrapper = styled.div`
  width: 450px;
  background-color: ${props => props.theme.formAmbiance == "light" ? props.theme.containerBackground : 'transparent'};
  margin: ${props => props.theme.containerPosition || '0 auto'};
  border-radius: ${props => props.theme.containerRadius || '0px'};
  z-index: 20;
  &.with-back{
    h1{
      color: ${props => props.theme.formAmbiance == "light" ? props.theme.H1ColorDark : props.theme.H1ColorLight}; // A modifier formAmbiance & layoutAmbiance
    }

    h2{
      color: ${props => props.theme.formAmbiance == "light" ? props.theme.H2ColorDark : props.theme.H2ColorLight};
    }

    h3{
      color: ${props => props.theme.formAmbiance == "light" ? props.theme.H2ColorDark : props.theme.H2ColorLight};
    }
    p{
      color: ${props => props.theme.formAmbiance == "light" ? props.theme.PColorDark : props.theme.PColorLight};
    }
  }
  

  @media (max-width: 991px){
    position: relative;
    margin: 0 auto!important;
    width: 90%;
  }
`;

export const TitleH1 = styled.h1`
  font-family: ${props => props.theme.fontFamilyTitle || 'Arial, Helevetica, sans-serif'};
  font-size: ${props => props.theme.H1FontSize || '28px'};
  line-height: ${props => props.theme.H1LineHeight || '32px'};
  letter-spacing: ${props => props.theme.H1LetterSpacing || '0px'};
  text-transform: ${props => props.theme.H1TextTransform || 'initial'};
  font-weight: ${props => props.theme.H1FontWeight || 'bold'};
  margin-bottom: 20px;
  color: ${props => props.theme.layoutAmbiance == 'dark' ? props.theme.H1ColorLight : props.theme.H1ColorDark};

`;

export const TitleH2 = styled.h2`
  font-family: ${props => props.theme.fontFamilyTitle || 'Arial, Helevetica, sans-serif'};
  font-size: ${props => props.theme.H2FontSize || '18px'};
  line-height: ${props => props.theme.H2LineHeight || '24px'};
  letter-spacing: ${props => props.theme.H2LetterSpacing || '0px'};
  text-transform: ${props => props.theme.H2TextTransform || 'initial'};
  font-weight: ${props => props.theme.H2FontWeight || 'normal'};
  text-align: ${props => props.theme.rtl == 'true' ? "right" : "left"};
  color: ${props => props.theme.layoutAmbiance == 'dark' ? props.theme.H2ColorLight : props.theme.H2ColorDark};
  margin-bottom: 20px;
`;

export const TitleH3 = styled.h3`
  font-family: ${props => props.theme.fontFamilyTitle || 'Arial, Helevetica, sans-serif'};
  font-size: ${props => props.theme.H3FontSize || '15px'};
  line-height: ${props => props.theme.H3LineHeight || '21px'};
  letter-spacing: ${props => props.theme.H3LetterSpacing || '0px'};
  text-transform: ${props => props.theme.H3TextTransform || 'uppercase'};
  font-weight: ${props => props.theme.H3FontWeight || 'normal'};
  color: ${props => props.theme.layoutAmbiance == 'dark' ? props.theme.H3ColorLight : props.theme.H3ColorDark};
  margin-bottom: 20px;
  text-align: ${props => props.theme.rtl == 'true' ? "right" : "left"};

`;

export const TextP = styled.p`
  font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
  font-size: ${props => props.theme.PFontSize || '16px'};
  line-height: ${props => props.theme.PLineHeight || '22px'};
  font-weight: ${props => props.theme.PFontWeight || 'normal'};
  color: ${props => props.theme.layoutAmbiance == 'dark' ? props.theme.PColorLight : props.theme.PColorDark};
  white-space: pre-wrap;
  &.reinsurance-texts{
    display: none;
  }

`;
export const ButtonShop = styled.a<{hover?: 'primary' | 'secondary'}>`
  border-radius: ${props => props.theme.buttonRadius || '100px'};
  font-weight: ${props => props.theme.buttonFontWeight || 'normal'};
  
  padding: 15px 50px;
  font-size: ${props => props.theme.buttonFontSize || '16px'};
  letter-spacing: ${props => props.theme.buttonLetterSpacing || '0px'};
  border: 0px;
  margin: 0px;
  text-transform: ${props => props.theme.buttonTextTransform || 'initial'};
  font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
  position: relative;
  text-align: center;
  margin-top: 5px;
  &:hover{
    cursor: pointer;
  }
  @media (max-width: 767px){

  }

  ${props => props.hover == "primary" && css`
    background-color: ${props => props.theme.buttonPrimaryBackgroundColor || '#0d6efd'};
    color: ${props => props.theme.buttonPrimaryColor || '#fff'};
    border-width: ${props => props.theme.buttonPrimaryBorder || '0px'};
    border-style: solid;
    border-color: ${props => props.theme.buttonPrimaryBorderColor || 'transparent'};
    &:hover{
      background-color: ${props => props.theme.buttonPrimaryBackgroundColorHover || '#0b5ed7'};
    }
  `}

  ${props => props.hover == "secondary" && css`
    background-color: ${props => props.theme.buttonSecondaryBackgroundColor || 'grey'};
    color: ${props => props.theme.buttonSecondaryColor || '#fff'};
    border-width: ${props => props.theme.buttonSecondaryBorder || '0px'};
    border-style: solid;
    border-color: ${props => props.theme.buttonSecondaryBorderColor || 'transparent'};
    &:hover{
      background-color: ${props => props.theme.buttonSecondaryBackgroundColorHover || 'grey'};
    }
  `}

`;

export const Button = styled.button<{hover?: 'primary' | 'secondary'}>`
  border-radius: ${props => props.theme.buttonRadius || '100px'};
  font-weight: ${props => props.theme.buttonFontWeight || 'normal'};
  
  padding: 15px 50px;
  font-size: ${props => props.theme.buttonFontSize || '16px'};
  letter-spacing: ${props => props.theme.buttonLetterSpacing || '0px'};
  border: 0px;
  margin: 0px;
  text-transform: ${props => props.theme.buttonTextTransform || 'initial'};
  font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
  position: relative;
  text-align: center;
  margin-top: 5px;
  &:hover{
    cursor: pointer;
  }
  @media (max-width: 767px){

  }

  ${props => props.hover == "primary" && css`
    background-color: ${props => props.theme.buttonPrimaryBackgroundColor || '#0d6efd'};
    color: ${props => props.theme.buttonPrimaryColor || '#fff'};
    border-width: ${props => props.theme.buttonPrimaryBorder || '0px'};
    border-style: solid;
    border-color: ${props => props.theme.buttonPrimaryBorderColor || 'transparent'};
    &:hover{
      background-color: ${props => props.theme.buttonPrimaryBackgroundColorHover || '#0b5ed7'};
    }
  `}

  ${props => props.hover == "secondary" && css`
    background-color: ${props => props.theme.buttonSecondaryBackgroundColor || 'grey'};
    color: ${props => props.theme.buttonSecondaryColor || '#fff'};
    border-width: ${props => props.theme.buttonSecondaryBorder || '0px'};
    border-style: solid;
    border-color: ${props => props.theme.buttonSecondaryBorderColor || 'transparent'};
    &:hover{
      background-color: ${props => props.theme.buttonSecondaryBackgroundColorHover || 'grey'};
    }
  `}

`;

export const ButtonA = styled.a<{hover?: 'primary' | 'secondary'}>` /* uniquement pour immersif */
  border-radius: ${props => props.theme.buttonRadius || '100px'};
  font-weight: ${props => props.theme.buttonFontWeight || 'normal'};
  
  padding: 15px 50px;
  font-size: ${props => props.theme.buttonFontSize || '16px'};
  letter-spacing: ${props => props.theme.buttonLetterSpacing || '0px'};
  border: 0px;
  margin: 0px;
  text-transform: ${props => props.theme.buttonTransform || 'initial'};
  font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
  position: relative;
  text-align: center;
  margin-top: 5px;
  &:hover{
    cursor: pointer;
  }
  @media (max-width: 767px){

  }

  ${props => props.hover == "primary" && css`
    background-color: ${props => props.theme.buttonPrimaryBackgroundColor || '#0d6efd'};
    color: ${props => props.theme.buttonPrimaryColor || '#fff'};
    border-width: ${props => props.theme.buttonPrimaryBorder || '0px'};
    border-style: solid;
    border-color: ${props => props.theme.buttonPrimaryBorderColor || 'transparent'};
    &:hover{
      background-color: ${props => props.theme.buttonPrimaryBackgroundColorHover || '#0b5ed7'};
    }
  `}

  ${props => props.hover == "secondary" && css`
    background-color: ${props => props.theme.buttonSecondaryBackgroundColor || 'grey'};
    color: ${props => props.theme.buttonSecondaryColor || '#fff'};
    border-width: ${props => props.theme.buttonSecondaryBorder || '0px'};
    border-style: solid;
    border-color: ${props => props.theme.buttonSecondaryBorderColor || 'transparent'};
    &:hover{
      background-color: ${props => props.theme.buttonSecondaryBackgroundColorHover || 'grey'};
    }
  `}

`;


export const Input = styled.input`
  font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 15px 20px;
  border-radius: ${props => props.theme.inputRadius || '0.25rem'};
  font-size: ${props => props.theme.inputFontSize || '14px'};
  background: ${props => props.theme.inputBackgroundColor || '#fff'};
  border-width: ${props => props.theme.inputBorder || '0px'};
  border-style: solid;
  border-color: ${props => props.theme.inputBorderColor || 'transparent'};
  color: ${props => props.theme.inputColor || '#000'};
  height: 50px;
  display: block;
  position: relative;
  flex-grow: 1;
  box-sizing: border-box;
  width: 100%;
  flex-basis: 0;
  text-align: ${props => props.theme.rtl == 'true' ? "right" : "left"};
  &::placeholder {
    color: ${props => props.theme.inputPlaceHolderColor || 'grey'};
  }
  &.error{
    border: 1px solid red;
  }
  span{
    position: absolute;
    right: 5px;
  }
  &:focus{
    outline: inherit;
  }

  &[disabled]{
    //border-color: gray;
    color: gray;
  }
`;