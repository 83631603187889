import styled, { css } from "styled-components";
import icoArrowDownBlack from './images/ico-down-black.svg';
import icoArrowDownLight from './images/ico-down.svg';

export const LanguagesWrapper = styled.div`
  position: relative;
  z-index: 50;
  padding-right: 40px;
  @media (max-width: 991px){
    padding-right: 0px;
    z-index: 40;
    flex-grow: 1;
    width: 25%;
    min-width: 50px;
  }
`;

export const LanguagesSelectElement = styled.div`
  width: 100%;
  margin: 5px;
  @media (max-width: 767px){
   
  }
  &:first-child{
    margin-left: 0px;
  }
  &:last-child{
    margin-right: 0px;
  }
`;
export const LanguagesSelect = styled.select<any>`
    background-size: 11px auto;
    background-position: 92%;
    background-image: url(${props => props.theme.layoutAmbiance == "dark" ? icoArrowDownLight : icoArrowDownBlack});
    background-repeat: no-repeat;
    background-color: transparent;
    color: ${props => props.theme.layoutAmbiance == 'dark' ? props.theme.colorLight : props.theme.colorDark};
    border-color: transparent;
    width: 60px;
    -moz-appearance: none;
    -webkit-appearance: none;
    height: 50px;
    font-size: 10px;
    position: relative;
    text-transform: uppercase;
    padding: 12px 10px 12px 20px;
    @media (max-width: 991px){
      font-size: 12px;
    }
    &:focus{
      outline: inherit;
    }
    &:before{
      
    }
    &:after{
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      right: 10px;
      top: 10px;
      background-size: 20px auto;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url(${props => props.theme.layoutAmbiance == "dark" ? icoArrowDownLight : icoArrowDownBlack});
    }

    ${props => (
      props.location.pathname.startsWith('/message')
    ) && css`
      @media (max-width: 991px){
        
      }
      
    `
    }
`;
export const LanguagesOption = styled.option`
  color: #000;
`;
