import * as React from 'react';
import livstickApp from '../../models/livstick';
//import 'video.js/dist/video-js.css';
import videojs from 'video.js';
import { useParams, useNavigate } from "react-router-dom";
import Layout from '../layout';
import { FormattedMessage, useIntl } from 'react-intl';
import { TitleH1, TitleH2, TextP, Button, ButtonA, Input } from '../../widgets/base';
import { colors } from '../../variables';
import { ButtonPlayAgain, OverlayButtonImmersif } from './styled';
import OverlayEndImmersifContext from '../../context/Immersif';
import { withTheme } from 'styled-components';
import ScenesContext from '../../context/scenes';
import Parse from "parse";
const videoJsOptions = {
  controls: true,
  bigPlayButton: true,
  fluid: true,
};

const PreviewPage: React.FunctionComponent<any> = withTheme(({ theme }) => {

  const navigate = useNavigate();
  const params = useParams();
  const intl = useIntl();
  
  const introContext = React.useContext(ScenesContext);
  const videoEl = React.useRef(null);

  const [videoUrl, setVideoUrl] = React.useState(null);

  const [message, setMessage] = React.useState<string>();
  const [showOverlay, setShowOverlay] = React.useState<boolean>(false);
  const start = async () => {
    if (livstickApp.messageObject == null) return navigate('/landing');

    if (livstickApp.codeObject.get('status') == 0) navigate('/record');
    if (livstickApp.codeObject.get('status') > 0) setMessage(livstickApp.messageObject.get('content'));

    const videoFile: Parse.File = livstickApp.messageObject.get('contentFile');
    (window as any).restitutionApp = {
      videoFile: videoFile.url(),
      background: theme.backgroundSky,
      setShow: (forced) => setShowOverlay(forced ? forced : !showOverlay),
      deviceMotionMessage: intl.formatMessage({ id: "ar_txt_aut1" }),
      allowButtonText: intl.formatMessage({ id: "ar_txt_accept" }),
      denyButtonText: intl.formatMessage({ id: "ar_txt_deny" }),
    }
    setVideoUrl(videoFile.url());

  }

  React.useEffect(() => {
    start();
  }, [])

  React.useEffect(() => {
    if (videoUrl) {
      let script = document.createElement("script")
      script.src = (introContext as any).restitution;
      script.async = true;

      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      }
    }
  }, [videoUrl])

  if (!videoUrl) return null;
  return <div className="immersif-page">
    <Layout>
      <OverlayEndImmersifContext.Provider value={{ show: showOverlay, setShow: (forced) => setShowOverlay(forced ? forced : !showOverlay) }}>
        {videoUrl && <><div id="restitution-app"></div>
          {window.innerWidth < 645 && <div id="ar-switch" onClick={() => (window as any).setAR(!(window as any).ar)}>{(window as any).ar ? "3D" : "AR"}</div>}
          <div id="helper" onClick={() => (window as any).startExp()}>
            <Button color={colors.primary} hover="primary"><FormattedMessage id="show_txt_start" /></Button>
            {window.innerWidth < 645 && <span style={{ fontSize: "0.5rem", marginTop: "15px" }}><FormattedMessage id="show_txt_start_instruction" /></span>}
            </div></>}
        <OverlayButtonImmersif show={showOverlay}>
          <Button color={colors.secondary} hover="secondary" onClick={(event) => {
            (document.getElementById("myvideo") as any).play();
            setShowOverlay(false);
          }}>
            <FormattedMessage id="show_txt_replay" />
          </Button>

          <ButtonA color={colors.secondary} hover="secondary" href={intl.formatMessage({ id: "show_txt_cta_shop_url" })}>
            <FormattedMessage id="show_txt_cta_shop" />
          </ButtonA>
          {livstickApp.messageObject && !livstickApp.messageObject.get('answeredTo') && !livstickApp.isPreview && <Button color={colors.primary} hover="primary" onClick={() => {
            livstickApp.answer = true;
            navigate('/record')
          }}>
            <FormattedMessage id="show_txt_answer" />
          </Button>}
        </OverlayButtonImmersif>
      </OverlayEndImmersifContext.Provider>
    </Layout>
  </div>
})

export default PreviewPage;