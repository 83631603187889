import styled, { css } from "styled-components";

export const OnMobile = styled.div`
  display: none;
  @media (max-width: 991px){
    display: block;
  }
`;

export const OnDesktop = styled.div`
  display: block;
  @media (max-width: 991px){
    display: none;
  }
`;

export const Space = styled.div`
  height: 20px;
`;


export const Row = styled.div`
  display: flex;
`;

export const Col = styled.div`
  flex: 1;
`;

export const Content = styled.div`
  padding: 40px;
  text-align: center;
  @media (max-width: 767px){
    padding: 15px;
  }
`;



export const Sep = styled.div`
  height: 1px;
  width: 20%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #aaa;
`;

export const MobileMask = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: rgba(255,255,255,1);
  width: 100%;
  height: 100%;
  display: none;
  @media (max-width: 991px){
    display: block;
  }
`;

export const StepNumber = styled.div`
  font-size: 14px;
  text-align: center;
  color: ${props => props.theme.layoutAmbiance == 'dark' ? props.theme.colorLight : props.theme.colorDark};
  text-transform: initial;
  font-weight: normal;
  font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
  z-index: 40;
  margin-bottom: 10px;
  position: relative;
`;



export const AlignCenter = styled.div`
  text-align: center;
`;

export const AlignRight = styled.div`
  text-align: right;
  display: flex;
  justify-content: ${props => props.theme.rtl == 'true' ? "flex-start" : "flex-end"};
  
  @media (max-width: 767px){
    justify-content: center;
  }
`;

export const AlignLeft = styled.div`
  text-align: left;
`;