import React, {useContext, useEffect} from 'react'
import SephoraBag from "../preview/assets/sephorabag.glb"
import SephoraStar from "../preview/assets/star.glb"
import { useNavigate } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { FormattedMessage, useIntl } from 'react-intl'
import ScenesContext from '../../context/scenes';
import livstickApp from '../../models/livstick';

import Layout from '../layout';
function Intro(props) {
  const navigate = useNavigate();
  const intl = useIntl();
  const introContext = useContext(ScenesContext);
  const fileEl = React.useRef(null);
  useEffect(() => {
    fileEl.current.addEventListener('change', (e) => {
      livstickApp.videoFile = fileEl.current.files[0];
      if ((livstickApp.videoFile.size / (1024*1024)) > 30 ) return alert(intl.formatMessage({id: "player_txt_warning_toobig"}));
      if (!livstickApp.videoFile.type.includes('video') ) return alert(intl.formatMessage({id: "player_txt_warning_format"}));
      navigate('message');
    });
    (window).introApp = {
      background: props.theme.backgroundSky,
    }
    let script = document.createElement("script")
    script.src = introContext.intro;
    script.async = true;

    document.body.appendChild(script);
    return () => {
      document.children[0].classList.remove("a-fullscreen");
      document.body.removeChild(script);
    }
  }, []);

  const uploadFile = () => {
    //livstickApp.postEvent(events.player_i);
    window.innerWidth < 747 ? fileEl.current.click() : navigate('record');
  }
  
  return (
    <div className="immersif-page"><Layout>
      <input type="file" style={{display: 'none'}} ref={fileEl} accept="video/*"/>
      <div id="intro-app"></div>
    <div id="button-record" onClick={uploadFile}><FormattedMessage id="player_txt_mobile_video_button" /></div>
    </Layout></div>
  )
}

export default withTheme(Intro)
