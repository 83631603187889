import * as React from 'react';
import { LogoWrapper, HeaderWrapper, WrapRight } from './styled';
import { Logo, ImageHeader } from './../base';
import { OnMobile, OnDesktop } from './../layout';
import { TitleH1, TitleH2, TitleH3, TextP, Button, Input, Wrapper } from '../../widgets/base';
import {Helmet} from "react-helmet";
import {FormattedMessage, injectIntl, useIntl} from 'react-intl';
import { HelpContainerOpenMobile, HelpWrapper, HelpList, HelpListItem, HelpContainer, HelpClose, HelpContent, HelpOpenMobile } from '../help/styled';
import Languages from '../languages';
import { useLocation } from 'react-router';
import useWebsite from '../../hooks/websiteHooks';

const Header:React.FunctionComponent = () => {
    const intl = useIntl();
    const location = useLocation();
    const {website} = useWebsite();
    const assets:any = website.assetsList;
    const [tabIndex, setTabIndex] = React.useState<number>(0);
    const [helpActive, setHelpActive] = React.useState<boolean>(false);

    const openTab = (index: number) => {
        setHelpActive(true);
        setTabIndex(index);
    }

    const closeTab = () => {
        setHelpActive(false);
    }
    return <HeaderWrapper className="header-wrapper">
        <OnMobile>
            <HelpContainerOpenMobile location={location}>
                <HelpOpenMobile onClick={() => openTab(0)} active={helpActive == true} />
            </HelpContainerOpenMobile>    
        </OnMobile>
        <LogoWrapper><Logo className="logo" src={assets.mainLogo} /></LogoWrapper>
        
        <OnMobile><Languages /></OnMobile>
        <WrapRight active={helpActive == true}>
            <HelpList active={helpActive == true}>
                <HelpListItem active={tabIndex == 0}><a onClick={() => openTab(0)}><FormattedMessage id="help_txt_menu_help"/></a></HelpListItem>
                <HelpListItem active={tabIndex == 1}><a onClick={() => openTab(1)}><FormattedMessage id="help_txt_menu_about"/></a></HelpListItem>
                <HelpListItem active={tabIndex == 2}><a onClick={() => openTab(2)}><FormattedMessage id="help_txt_menu_contact"/></a></HelpListItem>
            </HelpList>
            <Languages />
        </WrapRight>
        <HelpContainer active={helpActive == true}>
            <HelpContent>
            {tabIndex == 0 && <div>
            <h1><FormattedMessage id="help_txt_menu_help"/></h1>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "help_txt_text_help"})}}></div>
            </div>}

            {tabIndex == 1 && <div>
            <h1><FormattedMessage id="help_txt_menu_about"/></h1>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "help_txt_text_about"})}}></div>
            </div>}

            {tabIndex == 2 && <div>
            <h1><FormattedMessage id="help_txt_menu_contact"/></h1>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "help_txt_text_contact"})}}></div>
            </div>}
            </HelpContent>
            <HelpClose onClick={() => closeTab()}></HelpClose>
        </HelpContainer>
        
    </HeaderWrapper>;
}

export default Header;