import * as React from 'react';
import { MainWrapper } from './../base';
import videoMp4 from './images/video.mp4';
import poster from './images/poster.png';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";


const Main:React.FunctionComponent<any> = ({children}:any) => {
    return <MainWrapper>
        {children}
    </MainWrapper>;
}

export default Main;
