
import styled, { css } from "styled-components";

export const HeaderWrapper = styled.header`
  height: 80px;
  background: transparent;
  position: relative;
  align-items: center;
  width: 100%;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: ${props => props.theme.rtl == 'true' ? "row-reverse" : "row"};
  @media (max-width: 991px){
    text-align: center;
    padding-top: 5px;
    flex-direction: row-reverse;
  }
`;
export const WrapRight = styled.div<{active?: boolean}>`
  display: flex;
  align-items: center;
  @media (max-width: 991px){
    display: none;
    ${props => props.active && css`
      display: block;
    `}
  }
`;
export const LogoWrapper = styled.div`
  position: relative;
  z-index: 2;
  @media (max-width: 991px){
    flex-grow: 2!important;
    text-align: center;
    width: 50%!important;
  }
`;