import * as React from 'react';
import { FooterWrapper, ListFooterLink } from './styled';
import { Link } from 'react-router-dom';

import {FormattedMessage} from 'react-intl';
import useWebsite from '../../hooks/websiteHooks';


const Footer:React.FunctionComponent = () => {
  const {website} = useWebsite();
  const assets:any = website.assetsList;
  
  return <FooterWrapper className="footer-wrapper">
    {/* Suppression de l'alcool .... */}
    <ListFooterLink className="right">
      <li><a href="http://www.meemento.com" target="_blank"><FormattedMessage id="global_txt_footer_editedby"/></a></li>
      <li><a href={assets.legal_editor} target="_blank"><FormattedMessage id="global_txt_footer_legal"/></a></li>
      <li><a href={assets.legal_terms} target="_blank"><FormattedMessage id="global_txt_footer_tandc"/></a></li>
      <li><a href={assets.legal_privacy} target="_blank"><FormattedMessage id="global_txt_footer_privacy"/></a></li>
    </ListFooterLink>
  </FooterWrapper>;
}

export default Footer;