import styled, { css } from "styled-components";
import icoCross from './images/ico-cross.svg';
import icoOpenMobileBlack from './images/ico-help-mobile-black.svg';
import icoOpenMobile from './images/ico-help-mobile.svg';

export const HelpWrapper = styled.div`
  z-index: 50;
  font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
  @media (max-width: 991px){
    
  }
`;

export const HelpContainerOpenMobile = styled.div<any>`
  
  position: relative;
  ${props => (
      props.location.pathname.startsWith('/message')
    ) && css`
      @media (max-width: 991px){
        & > div{
          
        }
      }
      
    `
    }
`;


export const HelpContainer = styled.div<{active?: boolean}>`
  position: fixed;
  right: -2000px;
  top: 0px;
  z-index: 10000;
  background-color: #fff;
  width: 640px;
  height: 100vh;
  overflow-y: scroll;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  -webkit-box-shadow: 0px 0px 106px 8px rgba(0,0,0,0.45);
  -moz-box-shadow: 0px 0px 106px 8px rgba(0,0,0,0.45);
  box-shadow: 0px 0px 106px 8px rgba(0,0,0,0.45);
  ${props => props.active && css`
      right: 0px;
    `}
  @media (max-width: 991px){
    width: 100%;
    box-shadow: inherit;
    -webkit-box-shadow: inherit;
    -moz-box-shadow: inherit;
    right: -100%;  
    ${props => props.active && css`
      right: 0%;
    `}
  }
`;

export const HelpContent = styled.div`
  padding: 100px 80px;
  color: #000;
  text-align: center;
  padding-top: 120px;
  font-size: 12px;
  font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
  h1{
    margin-bottom: 20px;
  }
  @media (max-width: 991px){
    padding: 100px 50px;
  }
  p, ul li{
    font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
    font-size: ${props => props.theme.PFontSize || '16px'};
    line-height: ${props => props.theme.PLineHeight || '22px'};
    font-weight: ${props => props.theme.PFontWeight || 'normal'};
  }
  ul{
    margin: 0px;
    padding: 10px 20px;
  }
`;

export const HelpClose = styled.div`
  position: absolute;
  top: 24px;
  right: 30px;
  width: 30px;
  cursor: pointer;
  height: 30px;
  border-radius: 0px;
  border: 0px solid #000;
  background-image: url(${icoCross});
  background-size: 25px auto;
  background-position: center center;
  background-repeat: no-repeat;
  @media (max-width: 991px){
    right: 10px;
    top: 10px;
    left: inherit;
    z-index: 100;
  }
  &:hover{
    background-color: #ccc;
    border:1px solid #ccc;
  }
`;

export const HelpOpenMobile = styled.div<{active?: boolean}>`
  
  width: 25px;
  z-index: 50;
  height: 25px;
  padding-left: 30px;
  margin-top: -5px;
  background-image: url(${props => props.theme.layoutAmbiance == "dark" ? icoOpenMobile : icoOpenMobileBlack});

  background-size: 25px auto;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  
  @media (min-width: 992px){
    display: none;
  }
  ${props => props.active && css`
      display: none;
    `}
`;


export const HelpList = styled.ul<{active?: boolean}>`
  border-radius: 5px;
  margin: 0px;
  padding: 0px;
  color: ${props => props.theme.layoutAmbiance == 'dark' ? props.theme.colorLight : props.theme.colorDark};
  overflow: hidden;
  z-index: 110000;
  position: relative;
  display: flex;
  flex-direction: ${props => props.theme.rtl == 'true' ? "row-reverse" : "row"};
  font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
  ${props => props.active && css`
    background-color: transparent;
    color: #000!important;
    li a{
      color: #000;
    }
  `}
  @media (max-width: 991px){
    display: none;
    position: fixed;
    width: 100%;
    text-align: center;
    top: 40px;
    ${props => props.active && css`
      display: flex;
      justify-content: center;
    `}
  }
`;

export const HelpListItem = styled.li<{active?: boolean}>`
  text-align: center;
  list-style: none;
  border-right: 1px solid ${props => props.theme.layoutAmbiance == 'dark' ? props.theme.colorLight : props.theme.colorDark};
  &:last-child{
    border-right-width: ${props => props.theme.rtl == 'true' ? "1px solid" : "0px"};
  }
  a{
    
    text-decoration: inherit;
    padding: 0px 15px;
    display: block;
    font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
    color: ${props => props.theme.layoutAmbiance == 'dark' ? props.theme.colorLight : props.theme.colorDark};
    font-size: 14px;
    text-transform: initial;
    cursor: pointer;
    @media (max-width: 991px){
      font-size: 11px;
      padding: 0px 10px;
      height: 20px;
      line-height: 20px;
    }
    &:hover{
      color: grey;
    }

    ${props => props.active && css`
      a{
        background-color: #000;
        color: #fff;
      }
    `}
  }
`;

