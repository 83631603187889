import styled from "styled-components";

export const FooterWrapper = styled.footer`
  display: block;
  justify-content: space-between;
  width: 100%;
  position: relative;
  z-index: 2;
  padding: 0px 0px;
  text-align: right;
  @media (max-width: 991px){
    position: relative;
    left: inherit;
    bottom: inherit;
    margin-top: 0px;
    text-align: center;
    display: inline-block;
    justify-content: inherit;
    width: 100%;
    padding-bottom: 70px;
  }
`;


export const ListFooterLink = styled.ul`
  margin: 0px;
  padding: 0px 10px;
  padding-bottom: 20px;
  @media (max-width: 991px){
    width: 80%;
    margin: 0 auto;
    text-align: center;
    padding: 0px;
    padding-bottom: 30px;
  }
  @media (max-width: 767px){
    width: 100%;
    
  }
  &.right{
    text-align: left;
    float: left;
    display: flex;
    flex-direction: ${props => props.theme.rtl == 'true' ? "row-reverse" : "row"};
    @media (max-width: 991px){
        float: inherit;
        text-align: center;
        display: block;
      }
    li{
      text-align: right;
      float: right;
      &:first-child{
        margin-bottom: 5px;
      }
      a{
        color: ${props => props.theme.layoutAmbiance == 'dark' ? props.theme.colorLight : props.theme.colorDark};
      }
      @media (max-width: 991px){
        float: inherit;
        text-align: center;
        display: inline-block;
      }
    }
  }
  span{
    color: #000;
    text-transform: uppercase;
    font-weight: 900;
  }
  li {
    list-style: none;
    float: right;
    padding: 0px 4px;
    font-size: 10px;
    color: ${props => props.theme.layoutAmbiance == 'dark' ? props.theme.colorLight : props.theme.colorDark};
    text-transform: uppercase;
    font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
    @media (max-width: 991px){
        float: inherit;
        padding: 0px 10px;
        text-align: center;
        color: #616161;
        display: inline-block;
      }
      @media (max-width: 767px){
        font-size: 8px;
      }
    &:last-child{
      border-right: 0px;
    }
    a{
      color: ${props => props.theme.layoutAmbiance == 'dark' ? props.theme.colorLight : props.theme.colorDark};
      text-decoration: inherit;
      font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
      margin: 0px;
      text-transform: uppercase;
      font-weight: normal;
      &:hover{
        color: ${props => props.theme.layoutAmbiance == 'dark' ? props.theme.colorLight : props.theme.colorDark};
      }
    }
  }
`;

