import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const MessengerRedirect: React.FunctionComponent<any> = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    //console.log(window.location.search.replace('?', ''))
    navigate(window.location.search.replace('?', ''));
  },[]);

  return <></>;
}

export default MessengerRedirect;