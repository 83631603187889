import React from "react";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { IntlProvider } from "react-intl";
import translations from "./lang/fixture";
import livstickApp from "./models/livstick";
import Intro from "./pages/intro";
import PreviewImmersifPage from "./pages/preview/immersiveIndex";

import { ThemeProvider } from "styled-components";
import ConsentsContext from "./context/consents";
import ScenesContext from "./context/scenes";
import useWebsite from "./hooks/websiteHooks";
import MessengerRedirect from "./pages/messenger";
const RecordPage = React.lazy(() => import('./pages/record'));
const EditPage = React.lazy(() => import('./pages/edit'));
const RecordPageDefault = React.lazy(() => import('./pages/record/index_default'));
const PreviewPage = React.lazy(() => import('./pages/preview'));
const DefaultMessagePage = React.lazy(() => import('./pages/preview/defaultMessage'));
const MessagePage = React.lazy(() => import('./pages/message'));
const UploadPage = React.lazy(() => import('./pages/upload'));
const ErrorPage = React.lazy(() => import('./pages/error'));
const LandingPage = React.lazy(() => import('./pages/landing'));
const GatePage = React.lazy(() => import('./pages/gate'));
const TriagePage = React.lazy(() => import('./pages/triage'));
const SortPage = React.lazy(() => import('./pages/sort'));
const EndPage = React.lazy(() => import('./pages/end'));
const AnyedIntlProvider = IntlProvider as any;

const App: React.FC = () => {
  const [language, setLanguage] = React.useState<string>(navigator.language.split(/[-_]/)[0]);
  const { website, setLanguageWebsite, isPending } = useWebsite();

  React.useEffect(() => {
    livstickApp.onChangeLang = [async (lang) => {
      await setLanguageWebsite(lang);
      setLanguage(lang);
    }];
  }, []);
  console.log(isPending, language, translations);
  if (website.loaded) {
    try {
      document.getElementById('loader-svg').remove();
    } catch(e) {
      
    }
  }
  return (
    <>
      {!website.loaded ?
        <div></div>
        :
        <React.Suspense fallback={<div></div>}>
          <AnyedIntlProvider locale={language} messages={website.translations}>
            <ConsentsContext.Provider value={website.platform.get('consents')}>
              <ScenesContext.Provider value={website.platform.get("servicesSettings")}>
                <ThemeProvider theme={website.theme}>
                  <Router>
                    <Routes>
                      <Route element={<RecordPageDefault />} path="/record">

                      </Route>
                      <Route element={<ErrorPage />} path="/error" />
                      <Route element={<RecordPage />} path="/new" />
                      <Route element={<MessagePage />} path="/record/message" />
                      <Route element={<UploadPage />} path="/upload" />
                      <Route element={<EndPage />} path="/end" />
                      <Route element={<LandingPage />} path="/landing" />
                      <Route element={<SortPage />} path="/sort" />
                      <Route element={<PreviewPage />} path="/view/:id" />
                      <Route element={<DefaultMessagePage />} path="/default/message" />
                      <Route element={<RecordPage />} path="/edit/:id" />
                      <Route element={<PreviewPage />} path="/answer/:id" />
                      <Route element={<PreviewImmersifPage />} path="/immersif/:id" />
                      <Route element={<PreviewImmersifPage />} path="/immersif" />
                      <Route element={<PreviewImmersifPage />} path="/ar/:id" />
                      <Route element={<Intro />} path="/intro" />
                      <Route element={<TriagePage />} path="/:id" />
                      <Route element={<TriagePage />} path="/preview/:id" />
                      <Route element={<TriagePage />} path="/overwrite/:id" />
                      {/* <Route element={<EditPage />} path="/edit/:id" /> */}
                      <Route element={<EditPage />} path="/edit/:id/:key" />
                      <Route element={<LandingPage />} path="/" />
                      <Route element={<MessengerRedirect />} path="/messenger" />
                      
                    </Routes>
                  </Router>
                </ThemeProvider>

              </ScenesContext.Provider>
            </ConsentsContext.Provider>
          </AnyedIntlProvider>
        </React.Suspense>
      }
      <style>
        {website.loaded && website.theme.fontFace}
      </style>
    </>
  );
}

declare let module: object;

export default App;
