import styled, { css } from "styled-components";
import { colors } from './../../variables';

export const FlexButtons = styled.div`
  display: flex;
  
  justify-content: center;
  align-items: center;
  button{
    margin: 0px 10px;
  }
  a{
    margin: 0px;
    text-decoration: none;
  }
  @media (max-width: 767px){
    flex-direction: column;
    button{
      margin: 5px 0px;
    }
  }
`;
export const ShowWrapper = styled.div`
  width: 1000px;
  background-color: transparent;
  border-radius: 0px;
  position: relative;
  margin: 0 auto;
  color: #fff;
  z-index: 30;
  .video-js{
    border: 1px solid #aaa;
    margin: 0 auto;
  }
  text-align: center;
  .myVideo-dimensions.vjs-fluid{
    padding-top: 55vh;
    @media (max-height: 700px){
      padding-top: 40vh;
    }
  }
  @media (max-width: 1600px){
    width: 600px;
    margin-left: -300px;
    left: 50%;
  }
  @media (max-width: 991px){
    position: relative;
    margin-top: 0px;
    border:0px;
    left: inherit;
    background-color: transparent;
  }
  @media (max-width: 767px){
    position: relative;
    margin: 0 auto!important;
    left: inherit;
    margin-top: 0px;
    width: 95%;
  }
`;

export const ShowContent = styled.div`
  padding: 0px;
  padding-bottom: 30px;
  color: #fff;
  text-align: center;
  @media (max-width: 767px){
    padding: 30px 20px;
    color: #fff;
  }
`;

export const Video = styled.div`
  background-color: transparent;
  width: 100%;
  border-radius: 0px;
  overflow: hidden;
  position: relative;
  height: auto!important;
  margin: 0 auto;
  margin-top: 0px;
  border: 0px solid #aaa;
  @media (min-width: 992px){
    
  }
  @media (max-width: 767px){
    width: 100%;
    height: auto;
  }
`;

export const OverlayButtonImmersif = styled.div<{show: boolean}>`
  display: none;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.85);
  position: absolute;
  z-index: 50;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  button, a{
    min-width: 250px;
    text-align: center;
    display: block;
    padding: 15px 0px;
    @media (max-width: 991px){
      flex: inherit;
      line-height: inherit;
      padding: 15px 0px;
      margin: 10px 0px;
    }
  }
  ${props => props.show && `
    display: flex;
  `}
`

export const ButtonPlayAgain = styled.div`

`

export const ButtonsWrapper = styled.div`

`